.mainbanner8 {
  background: #101010;
  .mainparent8 {
  
    .parent8 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 19px 0px 20px 0px;
      margin: 0px 40px;
    }
    .para687{
      color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
padding: 10px 20px;
border-radius: 200px;
border: 1px solid #FF0083;
width: 218px;
background: #0E0E0E;
text-align: center;
display: flex;
align-items: center;
justify-content: center;

margin: 0 auto;
margin-bottom: 20px;
    }
    .para1 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .para2 {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .parentbar111 {
      padding-top: 90px;
      padding-bottom: 0px;
    }

    .firstdiv {
      color: unset;
      background-color: unset;
      box-shadow: unset;
      border-radius: unset;
      padding: 25px;
      border-radius: 15px;
      border: 1px solid #e6e6e6;
      background: #fff;
      backdrop-filter: blur(2px);
      padding: 25px;
      margin-bottom: 10px;
    }
    .secondiv {
      --bs-accordion-border-width: none;
      --bs-accordion-bg: none;
      padding: 60px 80px 0px 80px;
    }
    .thirddiv {
    }
    .textdivv {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      padding-left: 40px;
    }
    .textdiv2 {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
    }
    .accordion-body {
      color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 18.2px */
    }

    .accordion-button::after {
      background-image: url(../../../assets/add.svg);
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url(../../../assets/minus.svg);
    }
    .para444444 {
      color: #FFF;
text-align: center;
max-width: 748px;
margin: 0 auto;
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 49.5px */
text-transform: uppercase;
    }
    .parentbar111 {
      padding-top: 80px;
      padding-bottom: 0px;
    }
    
  }
  .mainfaqs {
    min-height: 100vh;
    background: #101010;
    .innerfaq {
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      padding-top: 60px;
      padding-bottom: 96px;
      .accordion {
        .active {
          // background: #ECECEC !important;
          border: 1px solid #FF0083 !important;
          background: #0E0E0E;
        }
        .accordion-item {
          border-top: none;
          border-right: 1px solid #242424;
          border-bottom: 1px solid #242424;
          border-left: 1px solid #242424;
          background: #0E0E0E;
          border-radius: 0px;
          
         
          
          // backdrop-filter: blur(2px);
  
          .accordion-header {
            border-bottom: none !important;
            padding: 0px;
            .accordion-button:not(.collapsed)::after {
              // background: url("../../../src/assets/minus.svg");
              background-repeat: no-repeat;
              background-position: unset;
              background-size: cover;
              margin: 0;
              padding: 0;
              width: 24px;
              height: 24px;
            }
            .accordion-button::after {
              // background: url("../../../src/assets/plus.svg");
              background-repeat: no-repeat;
              background-position: unset;
              background-size: cover;
              margin: 0;
              padding: 0;
              width: 24px;
              height: 24px;
            }
            .accordion-button {
              border-radius: 10px;
              box-shadow: none !important;
              padding: 0px;
              display: flex;
              align-items: center;
              background: #0E0E0E;
              border: none;
              border-radius: 0px;
              outline: none;
              justify-content: space-between;
              padding: 25px;
              p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                display: flex;
               
                justify-content: flex-start;
                gap: 18px;
                span {
                  color: #FFF;
                  font-weight: 700;          
                      }
              }
            }
          }
        }
        .accordion-body {
          // padding: 0px 86px 25px 86px;
          .para {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 18.2px */
          }
        }
      }
    }
    .headerrrrrr{
      border-top: 1px solid #242424;
    }
  }
}


@media (max-width: 1200px) {
  .mainbanner8 {
    .mainparent8 {
      .para444444 {
        font-size: 30px;
        text-align: center;
      }
      .secondiv {
        padding: 40px 20px 0px 20px;
      }
      .textdivv {
        padding-left: 10px;
        font-size: 16px;
      }
      .firstdiv {
        padding: 16px;
      }
      .textdiv2 {
        font-size: 16px;
      }
    }

    .mainfaqs{
      padding: 23px;
      p{
        font-size: 16px !important;
        gap: 10px !important;
        text-align: unset !important;

      }
    }
  }
}
