.main-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
background: #101010;
  // border-top: 1px solid #D1EDFA;
  padding-top: 43px;
  // .info{
  //   padding-left: 70px;
  // }
  .paddrightzero{
    padding-right: 0px;
    padding-left: 0px;
  }
  .link {
    display: flex;
    margin-top: 20px;

    p {
      font-size: 12px;
      font-weight: 500;
      color: #92a2a9;
      margin-top: 5px;
    }
  }
.errowdiv{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
}
.imgarrow{
  display: flex;
  align-items: end;
}
  .card {
    position: relative;
    border: none;
    margin-top: 20px;

    .handbox {
      width: 39px;
      height: 39px;
      position: absolute;
    }

    .text {
      margin-left: 50px;
      text-align: left;

      h6 {
        font-size: 18px;
        font-weight: 600;
        color: #264653;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        color: #92a2a9;
      }
    }
  }

  .toplineonly {
    color: #efefef;
    // width: 1234px;
    margin-top: 43px;
    // margin-bottom: 23px;
  }

  .info {
    .logo {
      width: 92.75px;
      height: 42px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    p {
      font-size: 12px !important;
      color: #92a2a9;
      margin: 20px 0;
      font-weight: 400;
    }
  }

  .num {
    margin-top: 8px;
    display: flex;
    margin-bottom: 14px;

    p {
      color: #264653;
      font-size: 12px !important;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .line {
    h4 {
      color: #fff;

      font-size: 16.071px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 19.286px */
      text-transform: capitalize;
    }

    .list {
      margin-top: 20px;

      p {
        color: #fff;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 15.4px */
        margin-top: 8px;
        text-transform: capitalize;
      }

      .para {
        color: #92a2a9;
        font-size: 12px !important;
        font-weight: 500;
        margin-top: 5px;
      }
    }

    .input {
      margin-top: 20px;
      position: relative;

      .email {
        width: 100%;
        padding: 12px 35px;
        border: 1px solid #d1edfa;
        border-radius: 2px;
      }

      .email::placeholder {
        color: #92a2a9;
        font-size: 12px;
        font-weight: 500;
      }

      .sms {
        position: absolute;
        left: 12px !important;
        top: 18px;
      }

      .send {
        background: #009de0;
        border-radius: 2px;
        padding: 8px 22px;
        border: none;
        font-size: 12px;
        font-weight: 500;
        color: white;
        position: absolute;
        right: 12px;
        top: 8px;
      }
    }
  }

  //   .hr {
  //     color: 1px solid #d1edfa;
  //     margin-top: 60px;
  //     border: none !important;
  //   }
  .gradientborder{
    width: 100%;
    background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
    padding-left: 1px;
    padding-top: 1px;
  .linelast {
    display: flex;
    /* height: 277px; */
    padding: 20px 0px 20px 20px;
    justify-content: flex-start;
    align-items: center;
    background: #101010;
    .listsss {
      display: flex;
      /* width: 497px; */
      /* height: 177px; */
      flex-direction: column;
      align-items: flex-start;
      .leeefftter {
        color: #fff;
        margin-top: 16px;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 33px */
        margin-bottom: 8px;
        position: relative;
      }
      .lefttlastpara {
        color: #fff;
        width: 309px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 19.8px */
        text-align: left;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
}
  .lastdddsdfcc {
    display: flex;

    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 23px;

    .lastbutmm {
      color: #a5a5a5 !important;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .last {
    p {
      color: #92a2a9;
      font-size: 10px;
      font-weight: 500;
      margin-top: -30px;
      padding: 10px 0;
      background: #ffffff;
      border-top: 1px solid #d1edfa;
    }

    .spa {
      color: #009de0;
    }
  }
  .imgsvg{
    position: absolute;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .main-footer {
    .link {
      display: flex;
      justify-content: center;
    }

    .paddrightzero{
      padding-right: 13px !important;
      padding-left: 13px !important;
    }
    .line {
      // text-align: left;
      .list {
        .para {
          font-size: 12px !important;
        }
      }

      .link {
        display: flex;
        justify-content: center;
      }
    }
  }

  .main-footer .num {
    display: flex;
    justify-content: center;
  }

  .main-footer .card {
    display: -webkit-inline-box;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .main-footer {
    .info {
      p {
        font-size: 10px !important;
      }
    }

    .line {
      .link {
        p {
          font-size: 12px;
        }
      }
     .gradientborder {
        margin-top: 20px;
    }
      .list {
        .para {
          font-size: 9px !important;
        }
      }
    }
  }
.gradientborder {
  margin-top: 50px;
}
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .main-footer {
    .card {
      .text {
        h6 {
          font-size: 13px;
        }

        p {
          font-size: 10px;
        }
      }
    }

    .info {
      p {
        font-size: 10px !important;
      }
    }

    .line {
      .list {
        .para {
          font-size: 10px !important;
        }
      }
    }
  }
}
@media (max-width: 800px) {

  .newonlybormarginnn{

    margin-right: -10px;
  }
  .main-footer .gradientborder {

    margin-top: 20px;
}
  .line {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
  .info {
    text-align: left;
    margin-bottom: 50px;
}
}

@media (max-width: 600px) {
  .line {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .main-footer .num {
    margin-bottom: 15px;
  }

  .main-footer .line .list p {
    margin-top: 15px;
  }

  .main-footer .social-icons-footer {
    display: flex;
    align-items: center;
  }

  .main-footer .card {
    margin-top: 40px;
  }
  .main-footer .info{
    padding-left: 6px;
  }
}

.line-handle {
  border-top: 1.5px solid #d1edfa;
  opacity: 1;
  width: 100%;
}
