.mainbanner {
  background-image: url("../../../assets/bgimg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #101010;

  .mainparent {
.inputhelp{
  color: #9A9A9A;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 24px */
display: flex;
width: 731px;
padding: 12px 12px 12px 15.5px;
align-items: center;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
background: #FFF;
border: unset;
border-radius: unset;
}
::placeholder{
  color: #9A9A9A;

}
.dropdown-toggle::after {
  display: none;
}
.pinkbutton{
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  padding: 12px 77px;
  background: #FF0083;
  border: unset;
}
.headerdiv{
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  padding-top: 174px;
  padding-bottom: 46px;
}
.paraaa1{
  color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 14px */
letter-spacing: 0.7px;
padding-top: 18px;
padding-bottom: 18px;
border-bottom: 1px solid #242424;
}
.dropdown-item:hover {
  background-color: unset;
}
.paraaa3{
  color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 14px */
letter-spacing: 0.7px;
padding-bottom: 18px;
border-bottom: 1px solid #242424;
white-space: wrap;
}
.paraaa4{
  color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 14px */
letter-spacing: 0.7px;
padding-top: 18px;
}
.btn:first-child:active{
  background-color: #FFF;
}
.dropdown-menu.show {
  width: 731px;
padding: 20px;
border-radius: 10px;
border: 1px solid #FF0083;
background: #171717;
box-shadow: -1px 2px 100px 0px rgba(0, 0, 0, 0.45);
}
.inputhelp22{
  border: unset;
  box-shadow: unset;
  padding: unset;
  width: 100%;
}
  }
}
@media(max-width:1050px) {
  .mainbanner {
    .mainparent {
    
      .inputhelp{
      width: 100%;
      }
      .headerdiv{
        padding-left: 20px;
        padding-right: 20px;
      }
      .pinkbutton{
        padding: 12px 40px;
      }
      .dropdown-menu.show {
        width: 100%;
      
      }
     
    }
  }
}