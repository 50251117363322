@font-face {
  font-family: "Mada", sans-serif;
  font-optical-sizing: auto;

}

body{
  font-family: "Mada", sans-serif;
}

html {
  scroll-behavior: smooth;

}

.mainlandang {
  overflow: hidden;
}

body {

  overflow-x: hidden;


  &::-webkit-scrollbar {
    display: none;
  }
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.grey {
  color: #888 !important;
}

.ptb20 {
  padding: 20px 0;
}

.ptb60 {
  padding: 60px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }


  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .padd-sm {
    padding: 0 !important;
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}

iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}

button.close {
  opacity: 1;
}

.custom-container {
  max-width: 2560px;
  width: 100%;
  padding-left: 64px;
  padding-right: 64px;
  margin: 0 auto;
}

@media (max-width:1199px){
  .custom-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating-circle {
  animation: rotate infinite linear 4s;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 1s ease-out, height 1s ease-out;
  height: 0 !important;
  transition: opacity 1s ease-out;
  /* Adjust the duration and timing function as needed */
}

.mainloader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  background-color: #001838;
  height: 100vh;
  position: relative;
  transition: width 1s ease-out, height 1s ease-out;
  // background-image: url('../src/assets/mainloaderbg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .loadertext {
    color: #FFF;
    text-align: center;
    font-family: "C100";
    font-size: 80px;
    font-style: normal;
    line-height: 110%;
    z-index: 99;
  }

  .logoloader {
    z-index: 99;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    margin-top: -100px;
  }
}

@media(max-width:991px){
  .mainloader .loadertext{
    font-size: 40px;
  }
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.39);
  backdrop-filter: blur(2.5px);
  opacity: unset !important;
}