.mainbanner9 {
  background-color: #101010;
  .mainparent0 {
 
.head111{
  color: #FFF;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 26.4px */
padding-bottom: 10px;
padding-top: 10px;
}
.para101{
  color: #FF0083;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 130%; /* 23.4px */
padding: 20px;

}
.para102{
  color: #FF0083;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 130%; /* 23.4px */
padding-right: 10px;
}
.para104{
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  padding-bottom: 10px;
}
  .para5555{
    padding: 20px;

  }
.headparentttt{
  padding: 110px;
}
.headparentttty{
  padding: 110px;
}
.nav-pills .nav-link.active{
  font-weight: 700;
  background-color: unset;
}
.nav-link {
  color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 24px */
text-align: start;
padding: 13px 0px;
}
.amberpillsouter_leader{
  display: flex;
  flex-direction: column;
}
.maintablepillsty{
  display: flex;
}
.headparentttt{
  display: flex;
  gap: 71px;
}
  }
  .offcanvas {

    background: #101010;
    width: 100%;
  }
  .offcanvas-header .btn-close{
    box-shadow: unset;
    background-color: #FFF;
  }
}
@media(max-width:1050px) {
  .d-flex {
    flex-direction: column;
  }
  .headparentttt{
padding: 40px !important;
  }
  .mainbanner9 {
    .mainparent0 {
    
      .inputhelp{
      width: 100%;
      }
      .headerdiv{
        padding-left: 20px;
        padding-right: 20px;
      }
      .pinkbutton{
        padding: 12px 40px;
      }
     .headparentttt{
     }
     .headparentttty{
display: block !important;
padding: 40px;
     }
     .rowwcolll{
      flex-direction: column;
    }
    .headparentttt{
      display: flex;
      gap: 71px;
      flex-direction: column;
    }
    .errowdiv{
      flex-direction: column;
    }
    }
  }
}
