.main-navbar {
    .parenthead
{
    display: flex;
padding: 20px 110px;
justify-content: space-between;
align-items: center;
background: #171717;
}
.parenthead2{
    display: flex;
    align-items: center;
    gap: 18px;
}
.leftborder{
    width: 1px;
height: 31px;
 background-color: #D9D9D9;
}
.help{
    color: #FDFDFD;
font-size: 21px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 1.05px;
}
.downlod{
    color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 14.4px */
padding: 10px 17px;
border-radius: 120px;
cursor: pointer;
border: 1px solid #FF0083;
}
.mylogo{
    cursor: pointer;
}
}
@media(max-width:900px) {
    .main-navbar {
        .parenthead
    {
        display: flex;
    padding: 20px 20px;
    justify-content: space-between;
    align-items: center;
    background: #171717;
    flex-direction: column;
        gap: 20px
    }
    .help{
        font-size: 12px;
    }
    .downlod{
        font-size: 10px;
    }
}
}