.mainbanner3 {
  background: #101010;
  .mainparent3 {
    .card1div{
      width: 279px;
height: 127px;
padding: 35px 0px 36px 0px;
border-radius: 10px;
border-radius: 10px;
background: #171717;
border: 1px solid #171717;

    }
    .card1div:hover{
      border: 1px solid #FF0083;


    }
    .imgcard1{
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
    .startedtext{
      color: #FFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      padding-top: 20px;
    }
    .headvcarddd{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .headparent6{
      display: flex;
align-items: center;
gap: 27px;
justify-content: center;
padding-top: 60px;
    }
    .headofparent11{
      display: flex;
      flex-direction: column;
    }
  }
}
@media(max-width:1300px) {
  .mainbanner3 {
    .mainparent3 {
    .parent111{
      padding: 30px;
    }
    .headofparent11{
      flex-direction: unset;
      justify-content: center;
      gap: 20px;
    }
    .headparent6{
      flex-direction: column;
    }
 
    }
    }
}
@media(max-width:685px) {
  .mainbanner3 {
    .mainparent3 {
.headofparent11{
  flex-direction: column;
}
    }
  }
}